@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/* App color */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  zoom: 90%;
}


.bg-app {
  background-color: rgb(32,33,35);
}
.bg-light-app {
  background-color: #d5f9d6;
}
.bg-button {
  background-color: rgb(32,33,35);
}
div.dk-bg-button,
button.dk-bg-button {
  background-color: rgb(32,33,35);
}

.text-app {
  color: rgb(32,33,35);
}

.border-app {
    border: solid;
    border-width: 2px;
    border-color: rgb(32,33,35);
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    rgb(32,33,35) 0%,
    rgb(32,33,35) 40%,
    transparent 50%,
    transparent
  );
  border-color: #3f0e40;
}

.text-app-color {
  color: rgb(32,33,35);
}

/* App color */

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scroll::-webkit-scrollbar {
    width: 5px;
    overflow-y: scroll !important;
    scrollbar-width: thin !important;
  }

  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f4f4f6;
    border-radius: 10px;
  }

  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #d6d5d5;
    border-radius: 10px;
  }

  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: gray;
  }

  div.warning-tooltip-prop {
    background-color: #fef6e1;
    max-width: 500px;
  }

.dk-kanban {
  user-select: none;
  /* overflow: scroll !important; */
  scroll-behavior: smooth;
}
.dk-kanban > .dk-kanban-stage-wrapper {
  user-select: none !important;
  /* background: #e6e6ee; */
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card {
  transition: transform 0.15s ease;
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card
  > .dk-kanban-default-card {
  background-color: #ffffff;
}
.cursor-grabbing {
  cursor: grabbing;
}
.cursor-move {
  cursor: move;
}
.d-flex {
  display: flex;
}
.stage-toggle-button:hover {
  background-color: #5a64d7;
}
/* Style the tab */
.tab {
  overflow: hidden;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab .tablinks:hover {
  color: #5a64d7;
}

.tab .tablinks.active {
  color: #5a64d7;
  border-bottom: 2px solid #5a64d7;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
/* .no-email-wrap {
  background-image: url("../assets/no-mail.svg");
  min-height: 13rem;
  background-position: right bottom;
  background-size: 12rem;
  background-repeat: no-repeat;
} */
.note-textarea {
  border-color: transparent !important;
  resize: none;
  background-color: #fef6e1;
}
.note-textarea:focus {
  border-color: transparent !important;
  resize: none;
  outline: none;
}
.stage-toggle-wrap div:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.stage-toggle-wrap div:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.selected-stage {
  background-color: #5a64d7 !important;
}
.deal-card-icon {
  -webkit-user-drag: none;
}
.data-link-wrapper > a {
  height: 2rem;
  line-height: 1rem;
  overflow: hidden;
}
.data-link-wrapper > a:visited {
  color: blue;
}
.dashboard-card-wrapper > .dashboard-card {
  width: 32%;
  margin-top: 1%;
  margin-bottom: 1%;
  /* margin-right: 1%; */
}
.dashboard-card-wrapper > .dashboard-card:nth-child(n / 3) {
  margin-right: 0%;
}
@media (max-width: 1025px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 49%;
  }
}
@media (max-width: 650px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 98%;
    margin-bottom: 2%;
  }
}

/* Borders */
.border-m-b {
  border: 0 solid rgb(220, 220, 220);
  border-bottom-width: 1px;
}

/* Radio Button Styles */
label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}


.flex-shrink-0 {
  flex-shrink: 0;
}
.stage-holder:hover .stage-wrap .stage-delete-icon {
  display: block;
}
.stage-holder > .stage-wrap > .stage-delete-icon {
  display: none;
}

.sidebar-transition-overflow {
  transition: 0.5s;
  overflow: hidden;
}

.sidebar-collapsed-width {
  min-width: 60px;
}

.sidebar-expanded-width {
  min-width: 180px;
}

.template-active {
  border: 2px solid blue;
}

.column-type-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.canvas-active {
  border: 0.5px solid rgb(0, 89, 255);
}

/* Horizontal Scroll Bar styles start */

.h-scroller {
  scrollbar-width: thin;
}

.h-scroller::-webkit-scrollbar {
  height: 0.5em;
  background-color: #f4f4f6;
}

.h-scroller::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* Horizontal Scroll Bar styles End */

[contenteditable]:focus {
  outline: 0px solid transparent;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.text-underline {
  text-decoration: underline;
}

.max-width-100 {
  max-width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input.incrementor-input::-webkit-outer-spin-button,
input.incrementor-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.incrementor-input[type="number"] {
  -moz-appearance: textfield;
}
.funnel-stepper-rollover {
  position: absolute;
  width: fit-content!important;
  z-index: 4;
  top: -30px;
}
.segment-multi-select>div.mr-l{
  margin : 0px !important
}
.segment-ctrl-first {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.segment-ctrl-last {
  border-radius: 0 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

/* .cust-card {
    background-image: url('../assets/images/Mask_Group_1.svg'), url('../assets/images/Group_31617.svg');
    background-position: left 60% bottom -1px, right center;
    background-repeat: no-repeat;
    background-size: auto 101%, auto 100%;
    background-origin: border-box, content-box;
} */

.sidebar {
  transition: width 0.3s;
}

.expandable-button {
  visibility: hidden;
}
.sidebar:hover .expandable-button {
  visibility: visible;
}

.sidebar-toggle {
  transition: left 0.3s;
}
.sidebar-menu-item {
  transition: visibility 0.3s;
}
.input-underline {
  border-bottom: 1px solid #dcdcdc;
}
.text-underline {
  text-decoration: underline;
}
.left-menu-button {
  text-decoration: none;
}

.active-menu-button,
.left-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.hover-effect:hover {
  background-color: rgb(236, 236, 236);

}
.document-view {
  height: calc(88vh - 129px);
  overflow-y: auto;
}
.doc-showdow {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.pl-0 {
  padding-left: 0px !important;
}
/* .react-pdf__Page{
  box-shadow: 0 0 8px rgb(0 3 0 / 50%);
  margin: 10px auto 0;
} */

@-moz-document url-prefix() {
  .pl-o{
    padding-left: 0px !important;
  }
  .ml-o{
    margin-left: 0px !important;
  }
  .input-w{
    width: -moz-available;
  }
  .ot-align {
    margin-bottom: -12px !important;
  }

}
.card-shadow {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.37) !important;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107,114,128,var(--tw-text-opacity));
}
.mb-0\.5 {
    margin-bottom: .125rem;
}
.mt-1 {
    margin-top: .25rem;
}
.px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
}
.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 transparent);
}
*, ::after, ::before {
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
}
.focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239,68,68,var(--tw-ring-opacity));
}
.rounded {
    border-radius: .25rem;
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.font-normal {
    font-weight: 400;
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209,213,219,var(--tw-border-opacity));
}
.border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248,113,113,var(--tw-border-opacity));
}
.w-92 {
    width:'92%'
}
.border {
    border-width: 1px;
}
.rounded {
    border-radius: .25rem;
}

/* .paid-card {
    width: 15vw;
    background-image: url('../assets/images/Paid.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 50%;
} */
.input-width {
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
}
.description-cashfree {
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  text-align-last: left;
  font-size: 1.05em
}

.overtime01 {
    height: 30px;
    border-width: thin;
    border-radius: 5px 5px 5px 5px;
    border-color: lightgrey;
    border-style: solid;
}

.overtime02 {
    height: 30px;
    border-width: thin;
    border-radius: 5px 5px 5px 5px;
    border-color: lightgrey;
    border-style: solid;
    margin-left:5px
}

.sidebar-arrow-icon{
  transition: transform 0.3s ease-out;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-360 {
  transform: rotate(360deg);
}

.bottom-margin {
  margin-bottom: -12px;
}
.border-n {
  border: none !important;
}

.info_error_icon {
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: visible;
  /* color: white; */
  border-radius: 4px;
  margin: 5px;
  max-width: 250px;
  width: 120px;
  padding: 8px;
  /* padding-right: 16px; */
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: grey;
  /* top: 0;
  left: 0;
  width: 220px;
  max-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px; */
  /* margin: 5px; */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.toogle-view :first-of-type {
  margin-right: 2px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%
}

.w-50 {
  width: 50%
}

.incomplete-profile-row {
    background-color: #FEECEA;
}



.navigation-scrollbar {
    overflow: hidden;
}

.navigation-scrollbar:hover {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbar-thin {
    scrollbar-width: thin;
}

.pointer-events-none {
    pointer-events: none;
}
.pb-xs{
  padding-bottom: 5px;
}

.variable-value {
  margin-top: -8px;
}
.disable-section {
    pointer-events: none;
    opacity: 0.75;
}

.disable-button {
    pointer-events: none;
    opacity: 0.75 !important;
}
.earning{
  background-color: #e8f5e9;
}
.deduction {
  background-color: #ffebee;
}
.expander-shadow {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.text-black {
  color: #000;
}
.d-none{
  display: none;
}
.w-45 {
  width: 45px;
}
.m-0{
  margin: 0px;
}
.h-70 {
  height: 70px;
}
.mt-0{
  margin-top: 0px;
}
.mt-auto {
    margin-top: auto !important;
}
.data-grid-badge-color-11{
  background-color: #ecbe90c0 !important;
}

.data-grid-badge-color-12{
  background-color: #b6d1e4 !important;
}

.ic-xxl{
  height: 120px;
}

.visibility-hidden {
    visibility: hidden;
}

.visibility-visible {
    visibility: visible;
}

.align-items-center-compo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.pb-4{
  padding-bottom: 4px;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
/* RESPONSIVE */
.notification-popup {
    width: 330px;
    position: absolute;
    margin: 5px auto;
}

.left-vert-divider {
    border-left: 2px #dededf solid;
    padding-left: 12px;
}

.text-msg-holder {
    display: flex;
    flex-flow: row wrap;
}

.vertical-spacing {
    margin-top: 0px;
}

.white-space-no-wrap {
    white-space: nowrap !important;
}

.mobile-width-auto {
    width: 400px;
}

.web-width-70 {
    width: 70%
}

.web-max-width-70 {
  max-width: 69%
}

.web-width-30 {
    width: 30%
}

.web-width-35 {
    width: 35%
}

.web-width-40 {
    width: 40%
}
.web-width-50 {
  width: 50%
}

.web-width-15 {
    width: 15%
}
.web-mr-l {
  margin-right:18px
}
.web-ml-auto {
  margin-left: auto;
}
.web-text-right{
  text-align: right !important;
}
.web-d-flex {
  display: flex;
}
.attachment-file-w {
  width: 54%;
}
@media only screen and (max-width: 800px) {
    .dk-sidebar-menu-toggle.expandable-menu-button {
        visibility: visible;
    }
    .notification-popup {
        width: 90vw;
        position: fixed;
        left:0;
        margin: 15px auto;
    }
    div.banner-holder {
        display: none;
    }
    .cust-card {
        background-image: url("//")
    }
    .left-vert-divider {
        border-left: none;
        padding-left: 0px;
    }
    .text-msg-holder {
        display: flex;
        flex-flow: column nowrap;
        text-align: left;
    }
    .vertical-spacing {
        margin-top: 8px;
    }
    .justify-content-center-mobile {
        justify-content: center !important;
    }
    .justify-content-start-mobile {
        justify-content: flex-start !important;
    }
    .editor-background-mobile {
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 9;
        background: rgba(0, 0, 0, 0.3);
    }
    .padding-empty-mobile {
        padding:0 !important;
    }
    .padding-top-mobile {
        padding-top: 12px !important;
    }
    .padding-bottom-mobile {
        padding-bottom: 6px !important;
    }
    .padding-bottom-mobile-10 {
      padding-bottom: 10px !important;
    }
    .marging-bottom-mobile {
      margin-bottom: 8px;
    }
    .margin-empty-mobile {
        margin:0 !important;
    }
    .mobile-width-auto {
        width: auto;
        margin-top: 8px;
    }
    .web-width-70 {
        width: 100%
    }

    .web-max-width-70 {
      max-width: 100%
    }

    .web-width-30 {
        width: 100%
    }
    .web-width-40 {
        width: 100%
    }
    .web-width-50 {
      width: 100%
    }
    .web-width-15 {
        width: 100%
    }
    .margin-top-mobile {
        padding-top: 30px !important;
    }
    .mobile-parent-width {
        width: 100% !important;
    }
    .mobile-horizontal-width {
        margin-left: 18px;
        margin-right: 18px;
    }
    .web-mr-l {
      margin-right: 0px;
    }
    .web-width-35 {
        width: 100%
    }
    .web-ml-auto {
      margin-left: inherit;
    }
    .web-text-right{
      text-align: inherit !important;
    }
    .web-d-flex {
      display: initial;
    }
    .attachment-file-w {
      width: 100%;
    }
}
.to-lower-case {
  text-transform: lowercase;
}
.ml-auto{
  margin-left: auto;
}
.cursor-pointer {
  cursor: pointer;
}

.width-tooltip-copy{
  justify-content: center;
  display: flex;
}
.controll-input::-webkit-outer-spin-button,
.controll-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.controll-input[type=number] {
  -moz-appearance: textfield;
}

.collapsable>div>div.fs-r{
  font-size:16px !important
}

.popup-overflow{
  overflow: visible !important;
}

@media only screen and (max-width: 800px) {
    .paid-card {
        background-image: none;
    }
}
.img-preview {
  width: 53%;
}
.d-flex-end {
  justify-content: flex-end !important;
}

.work-week-btn {
  border-radius: 4px !important;
}
.selected {
  background-color: #E6F2FF;
}
.border_bottom_workweek {
  border-bottom: 1px solid #ddd;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.dashboard-no-data {
  min-height: 230px;
}
.white-space-break >
  text{
    white-space: break-spaces !important;
    line-break: anywhere;
  }
.line-break-anywhere {
  line-break: anywhere;
}
.warning-tooltip-prop {
  background-color: #fef6e1;
  max-width: 500px;
}
.pt-0{
  padding-top: 0px !important;
}
.pb-0{
  padding-bottom: 0px !important;
}
.ml-6 {
  margin-left: 6px !important;
}

.email-view {
    position: fixed;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 9998;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px 20px;
    top: 50%;
    font-size: 13px;
    width: 35%;
    height: 65%;
    text-align: left;
    box-shadow: 0 0 12px rgb(0 0 20 / 14%);
}
.payment-method-popup {
  min-height: 270px;
  padding: 16px !important;
}

/* ChatBot */
.light-mode {
  color: #002e73;
  background-color: #FDFDFD;
}
.dark-mode {
  color: #ffffff;    /* TODO:: change */
  background-color: #1a1a1a;    /* TODO:: change */
}
.chat-container {
  width: 100%;
  height: 100%;
}
/* .chat-log>div {
  max-width: 20%;
  max-width: 50%;
} */
.chat-message-chatbot {
  align-self: flex-start;
}
.chat-message-user {
  align-self: flex-end;
}
.msg-chatbot {
  /* background-color: rgb(255,255,255); */
  border-radius: 10px;
}
.msg-user {
  /* background-color: rgb(221,215,202); */
  border-radius: 10px;
}
.markdown-table {
  border-collapse: collapse;
  width: 100%;
}

/* .markdown-table th,
.markdown-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
} */
.markdown-table {
  padding: 10px;
  border-radius: 20px;
  clip: auto;
}

.markdown-table table {
  width: 100%;
  border-collapse: separate; /* Changed to separate to allow border-radius */
  border-spacing: 0; /* Added to remove space between cells */
  border: 1px solid #bababa; /* Adjusted border thickness for outer border */
  margin-bottom: 20px;
  border-radius: 10px; /* Border-radius for outer border */
  overflow: hidden;
}

.markdown-table table th,
.markdown-table table td {
  border: 1px solid #dcdcdc;
  /* border: 1px solid #dcdcdc; */ /* Commented out to remove individual cell borders */
  padding: 8px;
}

.markdown-table table th {
  background-color: #5f59ff;
  color: white;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.markdown-table table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* .markdown-table td {
  font-size: 0.8rem;
} */

@media (max-width: 1500px) {
  /* .chat-name {
    font-size: 0.7rem !important;
    overflow: hidden;
  } */
}

@media (max-width: 1300px) {
  .header img {
    display: block !important;
  }
  .chat-container {
    align-items: flex-start !important;
    width: 100%;
  }
  .chat-message-container {
    margin: 0 !important;
  }
  .chat-message-sub-container {
    max-width: 90% !important;
    /* margin: none; */
  }
  .chat-message {
    width: 80% !important;
  }
  .chat-input-container {
    width: 80% !important;
    left: 14% !important;
  }

}